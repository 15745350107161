.input {
  &__label {
    padding-top: 10px;
    padding-bottom: 10px;

    display: grid;
    justify-items: center;
    grid-row-gap: 10px;
  }

  &__label:first-of-type {
    padding-top: 0px;
  }

  &__box {
    width: 20rem;
  }
}

.hideSelection .css-1dimb5e-singleValue {
  display: none;
}
