@import '../../mixins';

.card {
  background-color: white;
  box-shadow: 3px 3px 5px black;
  border-radius: 0.4rem;

  margin: 1rem;
  display: grid;
  grid-template-columns: 1fr 3fr;
  justify-items: center;
  align-items: center;
  height: 4rem;

  &__visual {
    //background-color: blue;
    display: grid;
    justify-items: center;

    &--halves {
      grid-template-rows: 1fr 1fr;
      grid-row-gap: 10px;
    }

    &--full {
      grid-template-rows: 1fr;
    }
  }

  &__info {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font-size: 1rem;
    font-weight: 550;
  }

  &--mobile {
    margin: 0rem;
    border-radius: 0rem 0rem 0.6rem 0.6rem;
  }
}
