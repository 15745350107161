@import '../../mixins';

.sidebar {
  height: 100vh; /* 100% Full-height */
  width: 100%; /* 0 width - change this with JavaScript */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
  grid-column: 3 / 4;
  grid-row: 1/2;
  z-index: 100;

  display: grid;
  grid-template-columns: 50px 1fr;

  &__open {
    background-color: #01abe5;
    display: grid;
  }

  &__content {
    height: 100vh;
    background-color: #f8f8ff;
    display: none;

    &--ref {
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &--instructions {
      height: 100%;
      overflow-y: auto;
    }

    &--title {
      width: 100%;
      font-weight: 600;
      font-size: 3rem;
      text-align: center;
      text-decoration: underline;
    }

    &--noContent {
      font-weight: 300;
      font-size: 1.5rem;
      padding-left: 20px;
    }
  }

  &__button {
    width: 4vmin;
    height: 4vmin;
    color: white;
    cursor: pointer;

    &--container {
      align-self: center;
      justify-self: center;
      width: max-content;
      height: max-content;
    }
  }

  @include respond(phone) {
    display: none;
  }

  button {
    color: white;
    font-weight: bold;
    background-color: #01abe5;
    border: none;
    height: 2rem;
    width: 4rem;
    margin-top: 20px;

    &:hover {
      cursor: pointer;
    }
  }
}

.activate {
  grid-column: 2 / 4;
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */

  .sidebar__content {
    display: flex;
  }
}

h2 {
  text-align: center;
}

@media print {
  .sidebar__content--instructions {
    overflow-y: visible;
  }

  button {
    display: none;
  }
}
