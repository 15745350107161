@import '../../mixins';

.form {
  grid-column: 1 / 3;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr 5rem;
  //grid-row-gap: 4px;
  height: max-content;

  &__button {
    width: 100%;
    height: 3rem;
    justify-self: center;
    background-color: #01abe5;
    color: white;
    font-weight: bold;
    font-size: 1.4rem;
    border: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
    //grid-column: 1 / 3;

    @include respond(phone) {
      border-radius: 0px;
      grid-row: 3/4;

      height: 4rem;
      font-size: 2rem;
    }
  }

  &__inputs {
    z-index: 5;
    height: max-content;
    align-self: center;
  }

  &__full {
    grid-template-rows: 1fr;
  }
}
