@import '../../mixins';

.dashboard {
  display: grid;
  grid-template-columns: 2fr 8fr;
  grid-column-gap: 1rem;

  grid-row: 9 / 10;
  grid-column: 1 / 9;
  align-self: end;

  width: 100%;
  height: 75%;
  background-color: white;
  border-radius: 1.25rem 1.25rem 0rem 0rem;

  &__exit {
    display: grid;
    justify-items: center;
    align-items: center;
    font-size: 2.5rem;
    border-right: 2px solid #ababab;
  }

  &__icon {
    color: #181818;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

  &__info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
  }

  &__text {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-column-gap: 5px;

    & > * {
      margin: 0px;
      font-size: 1rem;
    }

    &--number {
      font-weight: 500;
    }

    &--unit {
      font-weight: 200;
    }
  }

  &__button {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
}
