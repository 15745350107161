@import '../../mixins';

.button {
  grid-row: 2 / 3;
  border-style: none;

  border-radius: 1rem;
  width: 100%;
  height: 100%;
  align-self: center;
  justify-self: center;

  color: white;
  font-weight: 600;
  display: grid;
  justify-items: center;
  align-items: center;

  &--start {
    grid-column: 1 / 3;
    background-color: #01abe5;
    font-size: 1.8rem;
  }
}

.stop {
  display: grid;
  grid-template-rows: max-content max-content 1fr max-content max-content;
  grid-template-columns: max-content 1fr;

  grid-row-gap: 5px;
  grid-column-gap: 1rem;
}

.info {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  justify-self: center;

  background-color: white;
  padding: 1rem;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  border-radius: 1rem;

  &__circle {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    grid-column: 1 / 2;

    &--red {
      background-color: red;
    }

    &--blue {
      background-color: blue;
    }

    &--orange {
      background-color: orange;
    }

    &--yellow {
      background-color: gold;
    }

    &--green {
      background-color: green;
    }
  }

  &__label {
    margin: 0px;
    grid-column: 2 / 3;
  }

  &__location {
    margin: 0px;
    grid-column: 2 / 3;
  }

  &__line {
    justify-self: center;
    border-right: 2px dashed black;

    grid-column: 1 / 2;
    grid-row: 2 / span 2;
  }
}

.container {
  padding: 0.5rem;
  grid-column: 1 / 9;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;

  grid-row-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  position: fixed;
}

.exit {
  position: fixed;
  top: 0.5rem;
  left: 0.5rem;
  width: 3rem;
  height: 3rem;

  background-color: white;
  border-radius: 50%;

  &__icon {
    color: black;
    font-size: 1.75rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }

  &__button {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    z-index: 200;
  }
}
