@import '../../mixins';

.menu {
  &--computer {
    grid-column: 1 / 3;
    grid-row: 1 / 4;

    margin: 6px 12px;
    border-radius: 10px;
    background-color: white;

    height: max-content;
    min-width: 333px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content 1fr;

    @include respond(phone) {
      display: none;
    }
  }

  &__title {
    width: 100%;
    text-align: center;
    grid-column: 1 / 3;
  }

  &--phone {
    display: none;

    @include respond(phone) {
      height: max-content;

      display: grid;
    }
  }

  &__direction {
    position: fixed;
    width: 100%;
  }
}

.input {
  display: none;
  position: fixed;
  top: 1rem;
  left: 50%;

  transform: translateX(-50%);

  @include respond(phone) {
    display: unset;
  }

  &--hidden {
    display: none;
  }
}

.hidden {
  display: none;
}
