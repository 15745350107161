.add {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  margin-bottom: 10px;

  &__button {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    z-index: 1;
    background-color: black;
  }

  &__overlay {
    background-color: black;
    color: white;
    font-weight: bold;
    opacity: 90%;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    z-index: 2;
    cursor: pointer;

    display: grid;
    justify-items: center;
    align-items: center;
  }
}

.directions {
  width: 100%;
  height: 5rem;
  background-color: #01abe5;
}
