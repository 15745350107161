@import 'mixins';

.main {
  width: 100%;
  height: 95dvh;
  grid-column: 1/3;
  grid-row: 1/2;

  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(9, 1fr);

  @include respond(phone) {
    grid-column: 1 / 4;

    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
  }
}

.app {
  height: 100dvh;
  display: grid;

  grid-template-columns: 1fr 350px 50px;
}
